<template>
    <div class="dashboard">

        <div class="dash-wrap"> 

            <div class="sidebar">
                <div class="filter">
                    <div class="wrap2">
                        <div class="item">
                            <label for="">Año</label>
                            <multiselect v-model="year" :options="yearOptions" :multiple="true" :max-height="160" placeholder="" open-direction="bottom"
                                :close-on-select="false" :clear-on-select="false" :preserve-search="false" >
                                <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} años seleccionados</span></template>
                            </multiselect>
                        </div>
                        <div class="item">
                            <label for="">Agente</label>
                            <multiselect v-model="vendors" :options="consultants" :multiple="true" :max-height="160" placeholder="" open-direction="bottom"
                                :close-on-select="false" :clear-on-select="false" :preserve-search="false" label="NAME" track-by="INITIALS">
                                <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} agentes seleccionados</span></template>
                            </multiselect>
                        </div>
                        <!-- <div class="item">
                            <search-supplier @supplier="findSupplier" v-click-outside="closeSuggest" :openSuggestions="openSuggestions"></search-supplier>
                        </div>
                        
                        <div class="tags">
                            <div class="wrapper-tags">
                                <template v-for="(item, idx) in suppliers">
                                    <vs-chip :key="idx" transparent color="primary" closable @click="removeSupplier(item, 1)">{{ item.CODE }}</vs-chip>
                                </template>
                            </div>
                        </div> -->
                    </div>

                    <div class="item">
                        <vs-select label="Filtro Fecha" v-model="dateFilter">
                            <vs-select-item :key="index" :value="item.code" :text="item.name" v-for="(item,index) in dateTypes" />
                        </vs-select>
                    </div>
                    <div class="item">
                        <vs-button color="primary" type="filled" @click="filterDashboard()">Filtrar</vs-button>
                    </div>
                </div>
                <!-- <div class="counter">
                    <div class="item">
                        <span>{{ $n(totalSales, 'currency') }}</span>
                        <p>Total</p>
                    </div>
                    <div class="item">
                        <span>{{ $n(totalDirect, 'currency') }}</span>
                        <p>Total Directo</p>
                    </div>
                    <div class="item">
                        <span>{{ $n(totalIndirect, 'currency') }}</span>
                        <p>Total Indirecto</p>
                    </div>
                </div> -->
            </div>
            
            <div class="content">
                <!-- <div class="widget">
                    <div class="subtitle">Ventas</div>
                    <bar-chart :key="`line-${key}`" :labels="arrSales.labels" axis="y"
                        :dataChart="arrSales.values" :height="350"></bar-chart>
                </div> -->
                <div class="gap-2 widget">
                    <div class="subtitle">Agentes - Superhabit</div>
                    <div class="table-wrapper with-border">
                        <vs-table :data="superHabit">
                            <template slot="thead">
                                <vs-th sort-key="CONSULTANT_NAME">Agente</vs-th>
                                <vs-th sort-key="DIRECT">Directo</vs-th>
                                <vs-th sort-key="INDIRECT">Indirecto</vs-th>
                                <vs-th sort-key="SUPER_HABIT_VS_DEFICIT">SUPER_HABIT_VS_DEFICIT</vs-th>
                                <vs-th sort-key="TOTAL_RETAIL">Retail</vs-th>
                            </template>
                            <template slot-scope="{ data }">
                                <template :data="tr"  v-for="(tr, indextr) in data">
                                    <vs-tr :key="indextr">
                                        <vs-td :data="data[indextr].CONSULTANT_NAME">     {{ data[indextr].CONSULTANT_NAME }} </vs-td>
                                        <vs-td :data="data[indextr].DIRECT">  {{ $n(data[indextr].DIRECT , 'currency') }} </vs-td>
                                        <vs-td :data="data[indextr].INDIRECT"> {{ $n(data[indextr].INDIRECT, 'currency') }} </vs-td>
                                        <vs-td :data="data[indextr].SUPER_HABIT_VS_DEFICIT">    {{ $n(data[indextr].SUPER_HABIT_VS_DEFICIT   , 'currency') }} </vs-td>
                                        <vs-td :data="data[indextr].TOTAL_RETAIL">    {{ $n(data[indextr].TOTAL_RETAIL   , 'currency') }} </vs-td>
                                    </vs-tr>
                                </template>
                            </template>
                        </vs-table>
                    </div>
                </div>    
                
                <div class="gap-2 widget">
                    <div class="subtitle">Agentes - Ventas</div>
                    <div class="table-wrapper with-border">
                        <vs-table :data="kpis">
                            <template slot="thead">
                                <vs-th sort-key="YEAR" class="center">Año</vs-th>
                                <vs-th sort-key="agent">Agente</vs-th>
                                <vs-th sort-key="January">Enero</vs-th>
                                <vs-th sort-key="February">Febrero</vs-th>
                                <vs-th sort-key="March">Marzo</vs-th>
                                <vs-th sort-key="April">Abril</vs-th>
                                <vs-th sort-key="May">Mayo</vs-th>
                                <vs-th sort-key="June">Junio</vs-th>
                                <vs-th sort-key="July">Julio</vs-th>
                                <vs-th sort-key="August">Agosto</vs-th>
                                <vs-th sort-key="September">Septiembre</vs-th>
                                <vs-th sort-key="October">Octubre</vs-th>
                                <vs-th sort-key="November">Noviembre</vs-th>
                                <vs-th sort-key="December">Diciembre</vs-th>
                                <vs-th sort-key="Total">Total</vs-th>
                            </template>
                            <template slot-scope="{ data }">
                                <template :data="tr"  v-for="(tr, indextr) in data">
                                    <vs-tr :key="indextr">
                                        <vs-td :data="data[indextr].YEAR">     {{ data[indextr].year }} </vs-td>
                                        <vs-td :data="data[indextr].agent">     {{ data[indextr].agent }} </vs-td>
                                        <vs-td :data="data[indextr].January">  {{ $n(data[indextr].January , 'currency') }} </vs-td>
                                        <vs-td :data="data[indextr].February"> {{ $n(data[indextr].February, 'currency') }} </vs-td>
                                        <vs-td :data="data[indextr].March">    {{ $n(data[indextr].March   , 'currency') }} </vs-td>
                                        <vs-td :data="data[indextr].April">    {{ $n(data[indextr].April   , 'currency') }} </vs-td>
                                        <vs-td :data="data[indextr].May">      {{ $n(data[indextr].May     , 'currency') }} </vs-td>
                                        <vs-td :data="data[indextr].June">     {{ $n(data[indextr].June    , 'currency') }} </vs-td>
                                        <vs-td :data="data[indextr].July">     {{ $n(data[indextr].July    , 'currency') }} </vs-td>
                                        <vs-td :data="data[indextr].August">   {{ $n(data[indextr].August  , 'currency') }} </vs-td>
                                        <vs-td :data="data[indextr].September">{{ $n(data[indextr].September, 'currency') }} </vs-td>
                                        <vs-td :data="data[indextr].October">  {{ $n(data[indextr].October , 'currency') }} </vs-td>
                                        <vs-td :data="data[indextr].November"> {{ $n(data[indextr].November, 'currency') }} </vs-td>
                                        <vs-td :data="data[indextr].December"> {{ $n(data[indextr].December, 'currency') }} </vs-td>
                                        <vs-td :data="data[indextr].Total">    {{ $n(data[indextr].Total   , 'currency') }} </vs-td>
                                    </vs-tr>
                                </template>
                            </template>
                        </vs-table>
                    </div>
                </div>
                       
            </div>
        </div>  

        <modal :show="modalShow" :dark="true" title="Código Acceso">
            <div slot="modal-body">
                <div class="modal-content" style="padding: 35px 35px 15px">
                    <p>Por favor ingresar el código de acceso</p>
                    <br>
                    <vs-input v-model="codeAccess"  :danger="errorCode" danger-text="Código incorrecto" v-on:keyup.enter="validAccess()"/>
                </div>
            </div>
            <div slot="modal-footer">
                <div class="modal-footer">
                    <vs-button color="primary" type="border" @click="validAccess()" :disabled="disabled">Ingresar</vs-button>
                </div>
            </div>
        </modal>

    </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import CatalogService from '@/services/CatalogService'
import BookingService from '@/services/BookingService'
import modal from "@/components/Modals/Basic.vue";
// import searchSupplier from '@/components/findSupplier.vue'
// import barChart  from '@/components/Charts/Bar'
const  { mapActions: mapAppSettingActions } = createNamespacedHelpers('appSetting')


export default {
    name: "BookingsView",
    components: {
        modal,
        // lineChart,
        // barChart,
        // searchSupplier
    },
    data () {
        return {
            key: 1,
            date: "",
            modalShow: true,
            codeAccess: "",
            errorCode: false,
            disabled: false,
            monthAbr: ["Ene","Feb","Mar","Abr","May","Jun","Jul","Ago","Sep","Oct","Nov","Dic"],
            salesDirectIndirect: [],
            statsDirectIndirect: [],
            statsValuesDirectIndirect: [],
            statsLabelsDirectIndirect: [],

            month: 0,
            year: [],
            yearOptions: [],
            monthOptions: ['Todos','Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre',],
            kpis: [],
            arrYear: {
                labels: [],
                values: []
            },
            arrLabel: {
                labels: [],
                values: []
            },
            comparative: {

                labels: [],
                values: []
            },
            showLineChart: false,
            showLineChartIndirect: true,
            showLineChartDirect: true,
            colors: ["#00ccb3","#1a75d6","#fac71e","#905bff","#f9893e","#fc573b","#df41b6"],
            kpisDirect: [],
            salesDirect:  {
                labels: [],
                values: []
            },
            kpisIndirect: [],
            salesIndirect:  {
                labels: [],
                values: []
            },
            totalDirect: 0,
            totalIndirect: 0,
            totalSales: 0,
            suppliers: [],
            openSuggestions: true,
            consultants:[],
            vendors:[],

            superHabit: [],
            arrSales:{
                lables: [],
                values: [],
            },
            dateFilter: "travelDate",
            dateTypes: [{ code: "travelDate", name:"Travel Date"}, {code: "sellDate", name: "Sell Date"}]
            
        }
    },
    async created () {
        let code =  JSON.parse(localStorage.getItem('CDG'));
        this.codeAccess = code
        if(this.codeAccess != "" || this.disabled != undefined){
            this.validAccess();
        }
        
        for (let i = new Date().getFullYear(); i > new Date().getFullYear() - 20; i--) {
            this.yearOptions.push(i);
        }

        this.filterDashboard();
        this.getConsultants();
        // this.loadingCharts()

         
    },
    directives: {
        'click-outside': {
            bind: function (el, binding, vnode) {
                console.log(el)
                el.clickOutsideEvent = (event) => {
                    if (!(el === event.target || el.contains(event.target))) {
                        vnode.context[binding.expression](event);
                    }
                };
                document.body.addEventListener("click", el.clickOutsideEvent);
            }
        }
    },
    methods: {
        ...mapAppSettingActions(["LOADING_STATUS"]),
        async filterDashboard() {
            this.getKpisByYear();
            this.getConsultSuperHabit();
        },
        async getConsultants(){
            this.consultants = await CatalogService.getConsultants({});         
        },
        formatMonthAmounts(data){
            let jan = data.January  != null ? data.January : 0;
            let feb = data.February != null ? data.February : 0;
            let mar = data.March != null ? data.March : 0;
            let apr = data.April != null ? data.April : 0;
            let may = data.May   != null ? data.May : 0;
            let jun = data.June  != null ? data.June : 0;
            let jul = data.July  != null ? data.July : 0;
            let aug = data.August != null ? data.August : 0;
            let sep = data.September != null ? data.September : 0;
            let oct = data.October   != null ? data.October : 0;
            let nov = data.November  != null ? data.November : 0;
            let dec = data.December   != null ? data.December : 0;
            return [jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec];
        },
        filterAgenciesCodes () {
            let arr = [];
            this.suppliers.forEach( item => {
                arr.push(item.CODE)
            })
            return arr;
        },
        filterVendorsCodes () {
            let arr = [];
            this.vendors.forEach( item => {
                arr.push(item.INITIALS)
            })
            return arr;
        },
        async getConsultSuperHabit(){
            
             let response = await BookingService.getConsultSuperHabit({ 
                groupBy: "CONSULTANTS_SUPERHABIT", 
                dateFilter: this.dateFilter,
                saleYears: this.year, 
                consultants: this.filterVendorsCodes(this.vendors),
            });    
            if((response.status/100) == 2){
                this.superHabit = response.data.data
            }
        
        },
        async getKpisByYear(){
            let response = await BookingService.getKpisByYear({ 
                    groupBy: "Consultant", 
                    saleYears: this.year, 
                    dateFilter: this.dateFilter,
                    consultants: this.filterVendorsCodes(this.vendors),
                    agencies: this.filterAgenciesCodes(this.suppliers)
                });    
            
            if((response.status/100) == 2){
                this.kpis = response.data.data
            
                let test = this.groupBy(this.kpis,'YEAR')
                console.log('Original', test)
                let arr2 = []
                for (const key in test) {
                    arr2.push({
                        year: key,
                        agents: this.groupBy(test[key],'VENDEDOR')
                    })
                }
                console.log('Arr2', arr2)
                let arr3 = []
                arr2.forEach( item => {
                    // console.log('item.agents', item.agents)
                    
                    for (const key in item.agents) {
                        // let obj = {}
                        let temArr = []
                        item.agents[key].forEach( agent => {
                            let tempObj = {
                                year : agent.YEAR,
                                month : this.monthAbr[parseInt(agent.MONTH) - 1],
                                costo : agent.COSTO,
                                pax : agent.PASAJEROS,
                                retail : agent.RETAIL,
                                agent : agent.VENDEDOR,
                            }
                            temArr.push(tempObj);
                            // obj = tempObj //agent.MONTH,
                            // obj['month'] = this.monthAbr[parseInt(agent.MONTH) - 1],
                            // obj['costo'] = agent.COSTO,
                            // obj['pax'] = agent.PASAJEROS,
                            // obj['retail'] = agent.RETAIL,
                            // obj['agent'] = agent.VENDEDOR,
                            // obj['year'] = agent.YEAR,
                            // console.log('agent', agent.MONTH)
                        });
                        // console.log('obj', obj)
                        let test = []
                        let counter = 1;
                        let obj= {
                                year : "",
                                pax : "",
                                agent : "",
                                January: '',
                                February: '',
                                March: '',
                                April: '',
                                May: '',
                                June: '',
                                July: '',
                                August: '',
                                September: '',
                                November: '',
                                December: '',
                                October: '',
                                Total: 0,
                                
                            }
                        temArr.forEach( item => {
                           // console.log(item);
                            obj.year= item.year;
                            obj.pax= item.pax;
                            obj.agent=item.agent;
                            obj.Total = obj.Total + item.retail    
                            
                            switch (item.month) {
                                case 'Ene':
                                    console.log('HERE')
                                    obj['January'] = item.retail
                                    break;
                                case 'Feb':
                                    obj['February'] = item.retail
                                    break;
                                case 'May':
                                    obj['May'] = item.retail
                                    break;
                                case 'Apr':
                                    obj['April'] = item.retail
                                    break;
                                case 'Mar':
                                    obj['March'] = item.retail
                                    break;
                                case 'Jun':
                                    obj['June'] = item.retail
                                    break;
                                case 'Jul':
                                    obj['July'] = item.retail
                                    break;
                                case 'Aug':
                                    obj['August'] = item.retail
                                    break;
                                case 'Sep':
                                    obj['September'] = item.retail
                                    break;
                                case 'Oct':
                                    obj['October'] = item.retail
                                    break;
                                case 'Nov':
                                    obj['November'] = item.retail
                                    break;
                                case 'Dic':
                                    obj['December'] = item.retail
                                    break;
                                default:
                                    switch (counter) {
                                        case 1:
                                            obj['January'] = 0;
                                            break;
                                        case 2:
                                            obj['February'] = 0;
                                            break;
                                        case 3:
                                            obj['May'] = 0;
                                            break;
                                        case 4:
                                            obj['April'] = 0;
                                            break;
                                        case 5:
                                            obj['March'] = 0;
                                            break;
                                        case 6:
                                            obj['June'] = 0;
                                            break;
                                        case 7:
                                            obj['July'] = 0;
                                            break;
                                        case 8:
                                            obj['August'] = 0;
                                            break;
                                        case 9:
                                            obj['September'] = 0;
                                            break;
                                        case 10:
                                            obj['October'] = 0;
                                            break;
                                        case 11:
                                            obj['November'] = 0;
                                            break;
                                        case 12:
                                            obj['December'] = 0;
                                            break;
                                    }
                                    break;
                            }
                            counter++;
                        });

                            test.push(obj)
                        arr3.push(test);
                    }
                });
                console.log('arr3', arr3)
                this.kpis = []
               

                for(var i = 0; i < arr3.length; i++)
                {
                    this.kpis = this.kpis.concat(arr3[i]);
                }
            
            }

            
        },
        groupBy (array, key){
            // Return the end result
            return array.reduce((result, currentValue) => {
                // If an array already present for key, push it to the array. Else create an array and push the object
                (result[currentValue[key]] = result[currentValue[key]] || []).push(
                currentValue
                );
                // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
                return result;
            }, {}); // empty object is the initial value for result object
         },
        async getDirectSales(){
            let response = await BookingService.getKpisByYear({ 
                groupBy: "YEAR", 
                dateFilter: this.dateFilter,
                saleYears: this.year, 
                consultants: this.vendors,
                suppliers: this.suppliers,
                saleType: 'D'
            }); 
            if((response.status/100) == 2){
                this.kpisDirect = response.data.data
                let tempArrValues = []
                let counter = 0;
                this.totalDirect = 0;
                this.kpisDirect.forEach(item => {
                    // console.log({ josue: item.YEAR})
                    this.comparative.labels.push(item.YEAR)

                    tempArrValues.push(
                        {
                            label: item.YEAR,
                            backgroundColors: this.colors[counter],
                            data: this.formatMonthAmounts(item)
                        }
                    )
                    
                    this.totalDirect = this.totalDirect + item.TOTAL
                    counter > 6 ? counter = 0 : counter++

                });
                this.salesDirect.values = tempArrValues
                this.key++;
            }
        },
        async getIndirectSales(){
            let response = await BookingService.getKpisByYear({ 
                groupBy: "YEAR", 
                dateFilter: this.dateFilter,
                saleYears: this.year, 
                consultants: this.vendors,
                suppliers: this.suppliers,
                saleType: 'I'
            }); 
            if((response.status/100) == 2){
                this.kpisIndirect = response.data.data
                let tempArrValues = []
                let counter = 0;
                this.totalIndirect = 0;
                this.kpisIndirect.forEach(item => {
                    // console.log({ josue: item.YEAR})
                    this.comparative.labels.push(item.YEAR)

                    tempArrValues.push(
                        {
                            label: item.YEAR,
                            backgroundColors: this.colors[counter],
                            data: this.formatMonthAmounts(item)
                        }
                    )
                    
                    this.totalIndirect = this.totalIndirect + item.TOTAL
                    counter > 6 ? counter = 0 : counter++

                });
                this.salesIndirect.values = tempArrValues
                this.key++;
            }
        },
        validAccess() {
            if(this.codeAccess == "asuaire2022") {
                this.modalShow = false
                this.status = "done"
                localStorage.setItem('CDG', JSON.stringify(this.codeAccess))
            } else {
                this.errorCode = true
            }
        },
        findSupplier(response) {
            if(this.suppliers.findIndex(item => item.CODE == response.CODE) < 0){
                this.suppliers.push(response)
            }
        },
        removeSupplier(item) {
            this.suppliers.splice(this.suppliers.indexOf(item), 1)
        },
        closeSuggest(){
            this.openSuggestions = !this.openSuggestions
        },
        
    }
}
</script>